import React, {useContext} from 'react';
import {Carousel} from 'react-responsive-carousel';
import tatry from '../../assets/images/tatry.jpg';
import ntatry from '../../assets/images/ntatry.jpg';
import orava from '../../assets/images/orava.png';
import vtatry from '../../assets/images/vtatry.jpg';
import liptov from '../../assets/images/liptov.jpg';
import slovraj from '../../assets/images/slovraj.jpg';
import {SearchContext} from '../../Context';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom"; // Adjust the path as necessary

function Favourite() {
    const {t} = useTranslation();
    const {updateSearchParams} = useContext(SearchContext);

    const handleClick = (locality) => {
        updateSearchParams({locality});
    };

    return (
        <div className="box">
            <div className="home-box-left">
                <h2>{t('favouritePlacesTitle')}</h2>
                <Carousel className="favourite-carousel" showThumbs={false} showStatus={false} infiniteLoop autoPlay>
                    <div className="image-grid box-padding">
                        {item('Tatry', 651, 0, 2, handleClick, t)}
                        {item('Nízké Tatry', 21, 1, 1, handleClick, t)}
                        {item('Orava', 42, 2, 1, handleClick, t)}
                        {item('Slovenský Ráj', 69, 4, 1, handleClick, t)}
                        {item('Liptov', 21, 3, 1, handleClick, t)}
                        {item('Vysoké Tatry', 151, 5, 2, handleClick, t)}
                    </div>
                    <div className="image-grid box-padding">
                        {item('Tatry', 651, 0, 2, handleClick, t)}
                        {item('Nízké Tatry', 21, 1, 1, handleClick, t)}
                        {item('Orava', 42, 2, 1, handleClick, t)}
                        {item('Slovenský Ráj', 69, 4, 1, handleClick, t)}
                        {item('Liptov', 21, 3, 1, handleClick, t)}
                        {item('Vysoké Tatry', 151, 5, 2, handleClick, t)}
                    </div>
                </Carousel>
            </div>
        </div>
    );
}

function item(locality, values, foto, colum, handleClick, t) {
    const images = [
        {id: 0, src: tatry, alt: t('tatryAlt')},
        {id: 1, src: ntatry, alt: t('ntatryAlt')},
        {id: 2, src: orava, alt: t('oravaAlt')},
        {id: 3, src: liptov, alt: t('liptovAlt')},
        {id: 4, src: slovraj, alt: t('slovrajAlt')},
        {id: 5, src: vtatry, alt: t('vtatryAlt')},
    ];

    const formattedLocality = formatLocality(locality);

    return (
        <Link
            to={`/search/${formattedLocality}`} // Use formatted locality here
            className="grid-item relative item"
            style={{gridColumn: 'span ' + colum}}
            onClick={() => handleClick(locality)}
        >
            <img src={images[foto].src} alt={images[foto].alt} className="zoom-img carousel-img"/>
            <div className="carousel-text">
                <h3>{locality}</h3>
                <span>{values} {t('options')}</span>
            </div>
        </Link>
    );
}

function formatLocality(locality) {
    return locality.trim().replace(/\s+/g, '-');
};

export default Favourite;

import React, { useState } from 'react';
import axios from 'axios';
import login_back from "../assets/images/login_back.webp";
import { useTranslation } from 'react-i18next';

const ForgotPassPage = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false); // Přidání správy zapamatování
    const [error, setError] = useState(''); // Přidání správy chyb
    const [loading, setLoading] = useState(false); // Přidání správy načítání

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Nastavení stavu načítání
        setError(''); // Resetování chyb

        try {
            // Odeslání přihlašovacích údajů na server
            const response = await axios.post('/api/forgot-pass', { email, password });
            // Uložení uživatelských informací a tokenu
            console.log('Reset successful:', response.data);

            if (rememberMe) {
                localStorage.setItem('token', response.data.token); // Uložení tokenu do localStorage
            } else {
                sessionStorage.setItem('token', response.data.token); // Uložení tokenu do sessionStorage
            }

            // Můžete přesměrovat uživatele nebo provést další akce
        } catch (err) {
            console.error('Login failed:', err);
            setError(t('loginError')); // Nastavení chybové zprávy
        } finally {
            setLoading(false); // Nastavení stavu načítání
        }
    };

    return (
        <div className="login-page">
            <div className="login-form">
                <h2>{t('passwordRecovery')}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            placeholder={t('email')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>} {/* Chybová zpráva */}
                    <button type="submit" className="button-60 search-btn width-100 mar-20" disabled={loading}>
                        {loading ? t('verifying') : t('send')}
                    </button>
                </form>
                <div className="login-links">
                    {t('loginPrompt')} <a href="/login">{t('loginHere')}</a>
                </div>
            </div>
            <img src={login_back} alt={t('loginBackgroundAlt')} className="login_background"/>
        </div>
    );
};

export default ForgotPassPage;

// src/components/RegistrationForm.js
import React from 'react';
import {useTranslation} from 'react-i18next';

const RegistrationForm = ({formData, handleChange, handleSubmit, handleBack, loading, error}) => {
    const {t} = useTranslation();

    return (
        <form onSubmit={handleSubmit} className="form">
            <div className="section">
                <h2 className="p-0 mar-1">1. Kontaktné údaje</h2>
                <div className="form-group">
                    <input type="email" id="email" value={formData.email} onChange={handleChange}
                           placeholder={t('email') + '*'} required/>
                </div>
                <div className="form-group-grouped">
                    <div className="form-group small">
                        <select id="salutation" value={formData.salutation} onChange={handleChange}>
                            <option value="">{t('salutation')}</option>
                            <option value="pán">{t('mr')}</option>
                            <option value="pani">{t('mrs')}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <input type="text" id="first_name" value={formData.first_name} onChange={handleChange}
                               placeholder={t('firstName') + '*'} required/>
                    </div>
                    <div className="form-group">
                        <input type="text" id="last_name" value={formData.last_name} onChange={handleChange}
                               placeholder={t('lastName') + '*'} required/>
                    </div>
                </div>
                <div className="form-group">
                    <input type="tel" id="phone" value={formData.phone} onChange={handleChange}
                           placeholder={t('phone') + '*'} required/>
                </div>
            </div>

            <div className="section">
                <h2 className="p-0 mar-1">2. Fakturačné údaje</h2>
                <div className="form-group">
                    <input type="text" id="companyName" value={formData.companyName} onChange={handleChange}
                           placeholder={t('companyName') + '*'} required/>
                </div>
                <div className="form-group">
                    <input type="text" id="street" value={formData.street} onChange={handleChange}
                           placeholder={t('street') + '*'} required/>
                </div>
                <div className="form-group">
                    <input type="text" id="city" value={formData.city} onChange={handleChange}
                           placeholder={t('city') + '*'} required/>
                </div>
                <div className="form-group">
                    <input type="text" id="postalCode" value={formData.postalCode} onChange={handleChange}
                           placeholder={t('postalCode') + '*'} required/>
                </div>
                <div className="form-group">
                    <input type="text" id="ico" value={formData.ico} onChange={handleChange} placeholder={t('ico')}/>
                </div>
                <div className="form-group">
                    <input type="text" id="dic" value={formData.dic} onChange={handleChange} placeholder={t('dic')}/>
                </div>
                <div className="form-group">
                    <input type="text" id="vat" value={formData.vat} onChange={handleChange} placeholder={t('vat')}/>
                </div>
            </div>

            <div className="form-footer">
                <button type="button" className="back-button" onClick={handleBack}>{t('back')}</button>
                <button type="submit" disabled={loading}>{loading ? t('loading') : t('submit')}</button>
            </div>
            {error && <p className="error-message">{error}</p>}
        </form>

    );
};

export default RegistrationForm;

import React, {useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDropzone} from 'react-dropzone';
import debounce from 'lodash.debounce';
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faMinus} from "@fortawesome/free-solid-svg-icons";
// Custom marker icon
const icon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    shadowSize: [41, 41],
});

const UpdateMapCenter = ({position}) => {
    const map = useMap();
    useEffect(() => {
        if (position) {
            map.setView(position, 15); // Změna pozice a zoomu mapy
        }
    }, [position, map]);
    return null;
};

const BasicInfo = ({formState, handleChange}) => {
    const {t} = useTranslation();
    const [mainFotoPreview, setMainFotoPreview] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState([50.0755, 14.4378]); // Výchozí pozice (Praha)
    const addressInputRef = useRef(null);

    // Funkce pro vyhledávání adres
    const searchAddress = async (query) => {
        if (!query) return;
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(query)}&format=json&addressdetails=1`);
            const data = await response.json();
            console.log('Address Search Data:', data); // Debugging output
            setSuggestions(data);
        } catch (error) {
            console.error('Chyba při vyhledávání adres:', error);
        }
    };

    // Debounced verze vyhledávací funkce
    const debouncedSearchAddress = debounce(searchAddress, 300);

    // Handler pro změnu adresy
    const handleAddressChange = (event) => {
        const query = event.target.value;
        handleChange(event);
        debouncedSearchAddress(query);
    };

    // Výběr adresy ze seznamu
    const handleSuggestionClick = (lat, lon, address) => {
        console.log(`Selected Lat: ${lat}, Lon: ${lon}`); // Debugging output

        // Převod lat a lon na čísla
        const latitude = parseFloat(lat);
        const longitude = parseFloat(lon);

        setSelectedPosition([latitude, longitude]);
        setSuggestions([]);

        // Aktualizace hodnoty v formuláři
        const event = {
            target: {
                id: 'address',
                value: address,
            },
        };
        handleChange(event);
    };

    // Handle file drop and generate preview URL
    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setMainFotoPreview(previewUrl);

            const event = {
                target: {
                    id: 'mainFoto',
                    value: file,
                },
            };
            handleChange(event);
        }
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false,
    });

    return (
        <div>
            <div className={"main-form"}>
                <div className="form-group start">
                    <div
                        {...getRootProps({className: `dropzone min-w-300 ${isDragActive ? 'active' : ''}`})}
                        style={{
                            backgroundImage: mainFotoPreview ? `url(${mainFotoPreview})` : 'none',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <input {...getInputProps()} />
                        {!mainFotoPreview && (
                            <p>{isDragActive ? t('dropHere') : t('dragOrClick')}</p>
                        )}
                    </div>
                </div>

                <div className={'flex flex-column start w-100 max-w-500'}>
                    <div className="form-group max-w-500 text-start">
                        <label htmlFor="name">{t('productName')}</label>
                        <input
                            type="text"
                            id="name"
                            placeholder={t('productNamePlaceholder')}
                            value={formState.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group max-w-500 start gap-20">
                        <div className="form-group text-start">
                            <label htmlFor="accommodationType" className={'text-start'}>{t('accommodationType')}</label>
                            <select
                                id="accommodationType"
                                value={formState.accommodationType}
                                onChange={handleChange}
                            >
                                <option value="">{t('selectType')}</option>
                                <option value="hotel">{t('hotel')}</option>
                                <option value="apartment">{t('apartment')}</option>
                                <option value="house">{t('house')}</option>
                                <option value="villa">{t('villa')}</option>
                                <option value="other">{t('other')}</option>
                            </select>
                        </div>
                        <div className={'flex flex-row gap-20 beetween'}>
                            <div className="form-group text-start">
                                <label htmlFor="priceFrom">{t('priceFrom')}</label>
                                <input
                                    type="number"
                                    id="priceFrom"
                                    placeholder={t('priceFrom')}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group text-start">
                                <label htmlFor="priceTo">{t('priceTo')}</label>
                                <input
                                    type="number"
                                    id="priceTo"
                                    placeholder={t('priceTo')}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group text-start">
                                <label htmlFor="accommodationType"
                                       className={'text-start'}>{t('currency')}</label>
                                <select
                                    id="currency"
                                    value={formState.currency}
                                    onChange={handleChange}
                                    className={'min-w-100'}
                                >
                                    <option value="eur">{t('EUR')}</option>
                                    <option value="czk">{t('CZK')}</option>
                                </select>
                            </div>
                        </div>
                        <div className={'flex flex-row gap-20 beetween'}>
                            <div className="form-group text-start">
                                <label htmlFor="bedrooms">{t('bedrooms')}</label>
                                <input
                                    type="number"
                                    id="bedrooms"
                                    className={'text-center'}
                                    min="0"
                                    value={formState.bedrooms}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group text-start">
                                <label htmlFor="bathrooms">{t('bathrooms')}</label>
                                <input
                                    type="number"
                                    id="bathrooms"
                                    className={'text-center'}
                                    min="0"
                                    value={formState.bathrooms}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className={'flex flex-row gap-20 beetween'}>
                            <div className="form-group text-start">
                                <label htmlFor="toilets">{t('toilets')}</label>
                                <input
                                    type="number"
                                    className={'text-center'}
                                    id="toilets"
                                    min="0"
                                    value={formState.toilets}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group text-start">
                                <label htmlFor="parkingSpaces">{t('parkingSpaces')}</label>
                                <input
                                    type="number"
                                    className={'text-center'}
                                    id="parkingSpaces"
                                    min="0"
                                    value={formState.parkingSpaces}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'flex flex-column start w-100'}>
                    <div className="form-group text-start w-available">
                        <label htmlFor="address">{t('adress')}</label>
                        <input
                            type="text"
                            id="address"
                            ref={addressInputRef}
                            placeholder="Vyhledejte adresu"
                            value={formState.address}
                            onChange={handleAddressChange}
                            required
                        />
                        {suggestions.length > 0 && (
                            <ul className="suggestions-list">
                                {suggestions.map((suggestion) => (
                                    <li
                                        key={suggestion.place_id}
                                        onClick={() => handleSuggestionClick(suggestion.lat, suggestion.lon, suggestion.display_name)}
                                    >
                                        {suggestion.display_name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div style={{width: '100%', height: '266px', marginTop: '19px', borderRadius: '1rem'}}>
                        <MapContainer center={selectedPosition} zoom={15} style={{height: '100%', width: '100%'}}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='Map data © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <UpdateMapCenter position={selectedPosition}/>
                            <Marker position={selectedPosition} icon={icon}>
                                <Popup>{t('selectedLocation')}</Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicInfo;

let menuItems;
export default menuItems = [
  {
    name: 'Chaty',
    subItems: [
      {
        name: 'Chaty na Slovensku',
        items: [
          { name: 'Liptov', number: 537 },
          { name: 'Vysoké Tatry', number: 287 },
          { name: 'Nízke Tatry', number: 526 },
          { name: 'Tatry', number: 340 },
          { name: 'Orava', number: 521 },
          { name: 'Malá Fatra', number: 291 },
          { name: 'Veľká Fatra', number: 230 },
          { name: 'Kysuce', number: 131 },
          { name: 'Spiš', number: 229 },
          { name: 'Jasná', number: 86 },
          { name: 'Slovenský Raj', number: 136 },
          { name: 'Terchová', number: 100 },
          { name: 'Turiec', number: 115 },
          { name: 'Zuberec', number: 52 },
          { name: 'Demänovská dolina', number: 71 },
          { name: 'Oščadnica', number: 76 },
          { name: 'Zázrivá', number: 51 },
          { name: 'Bachledova dolina', number: 89 },
          { name: 'Liptovská Mara', number: 189 },
          { name: 'Oravská priehrada', number: 46 },
          { name: 'Zemplínska Šírava', number: 37 },
          { name: 'Pieniny', number: 101 },
          { name: 'Oravice', number: 18 },
          { name: 'Donovaly', number: 27 },
          { name: 'Vyhne', number: 7 },
          { name: 'Kubínska Hoľa', number: 83 },
          { name: 'Krpáčovo', number: 16 },
          { name: 'Liptovský Ján', number: 34 },
          { name: 'Oravská Lesná', number: 46 },
          { name: 'Ždiar', number: 47 },
          { name: 'Podhájska', number: 27 }
        ]
      },
      {
        name: 'Chaty v Českej republike',
        items: [
          { name: 'Beskydy', number: 153 },
          { name: 'Morava', number: 529 },
          { name: 'Jeseníky', number: 186 },
          { name: 'Južné Čechy', number: 159 },
          { name: 'Orlické hory', number: 90 },
          { name: 'Krkonoše', number: 131 },
          { name: 'Vysočina', number: 76 },
          { name: 'Južná Morava', number: 136 },
          { name: 'Pálava', number: 67 },
          { name: 'Šumava', number: 67 },
          { name: 'Lipno', number: 30 },
          { name: 'Český raj', number: 57 },
          { name: 'Jizerské hory', number: 52 },
          { name: 'Krušné hory', number: 29 },
          { name: 'České Švajčiarsko', number: 30 },
          { name: 'Stredné Čechy', number: 30 }
        ]
      }
    ]
  },
  {
    name: 'Silvester 2024',
    subItems: [
      {
        name: 'Silvester 2024 na Slovensku',
        items: [
          { name: 'Chaty', number: 1803 },
          { name: 'Drevenice', number: 351 },
          { name: 'Penzióny', number: 405 },
          { name: 'Apartmány', number: 788 },
          { name: 'Ubytovanie v súkromí', number: 165 },
          { name: 'Hotely', number: 58 }
        ]
      },
      {
        name: 'Silvester 2024 v Českej republike',
        items: [
          { name: 'Chaty', number: 691 },
          { name: 'Drevenice', number: 65 },
          { name: 'Penzióny', number: 488 },
          { name: 'Apartmány', number: 474 },
          { name: 'Ubytovanie v súkromí', number: 95 },
          { name: 'Hotely', number: 93 }
        ]
      }
    ]
  },
  { name: 'Netradičné ubytovanie' },
  { name: 'Špeciálne ponuky' },
  {
    name: 'Dalšie ubytovanie',
    subItems: [
      { name: '', items: [{ name: 'Apartmány', number: '' }] },
      { name: '', items: [{ name: 'Penzióny', number: '' }] },
      { name: '', items: [{ name: 'Hotely', number: '' }] }
    ]
  },
  { name: 'Blog' },
];

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { postRequest } from '../services/apiService';
import config from "../Config";
const ArticleDetail = () => {
    const { slug } = useParams(); // Získání slugu z URL
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await postRequest(config.host + '/api/article', { slug });
                setArticle(response.data);
            } catch (error) {
                console.error("Chyba při načítání detailu článku:", error);
                setError("Nepodařilo se načíst detail článku.");
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, [slug]);

    if (loading) {
        return <p>Načítání...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!article) {
        return <p>Článek nebyl nalezen.</p>;
    }

    return (
        <div className="article-detail">
            <h1>{article.title}</h1>
            <img src={article.image || 'default-image.jpg'} alt={article.title} className="article-image" />
            <div className="article-content">
                <p>{article.content}</p>
            </div>
        </div>
    );
};

export default ArticleDetail;
const Checkbox = ({ id, label, checked, onChange }) => (
    <div className="form-group switch">
        <label className="switch-container switchLabel">
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onChange}
            />
            <span className="slider round"></span>
            <span className="switch-label">{label}</span>
        </label>
    </div>
);

export const CheckboxGroup = ({ options, formState, handleChange, t }) => (
    <div className={'flex flex-wrap gap-20'}>
        {options.map((option) => (
            <Checkbox
                key={option.id}
                id={option.id}
                label={t(option.label)}
                checked={formState[option.id]}
                onChange={handleChange}
            />
        ))}
    </div>
);

export const waters = [
    { id: 'lake', label: 'lake' },
    { id: 'indoorPool', label: 'indoorPool' },
    { id: 'jacuzzi', label: 'jacuzzi' },
    { id: 'waterpark', label: 'waterpark' },
    { id: 'poolArea', label: 'poolArea' },
    { id: 'bazen', label: 'withIndoorPool' }, // Změněno na "withIndoorPool" pro překlad
];


export const services = [
    { id: 'wifi', label: 'wifi' },
    { id: 'televizor', label: 'televizor' },
    { id: 'washingMachine', label: 'washingMachine' },
    { id: 'dryer', label: 'dryer' },
    { id: 'sauna', label: 'sauna' },
    { id: 'kitchen', label: 'kitchen' }, // Změněno na "kitchen"
    { id: 'stove', label: 'stove' }, // Změněno na "stove"
    { id: 'oven', label: 'oven' }, // Změněno na "oven"
    { id: 'kettle', label: 'kettle' }, // Změněno na "kettle"
    { id: 'fridge', label: 'fridge' }, // Změněno na "fridge"
    { id: 'freezer', label: 'freezer' }, // Změněno na "freezer"
    { id: 'microwave', label: 'microwave' }, // Změněno na "microwave"
    { id: 'dishwasher', label: 'dishwasher' }, // Změněno na "dishwasher"
    { id: 'allInclusive', label: 'allInclusive' }, // Změněno na "allInclusive"
    { id: 'parking', label: 'parking' }, // Změněno na "parking"
    { id: 'nearSkiSlope', label: 'nearSkiSlope' }, // Změněno na "nearSkiSlope"
    { id: 'towels', label: 'towels' }, // Změněno na "towels"
    { id: 'grill', label: 'grill' }, // Změněno na "grill"
    { id: 'cauldron', label: 'cauldron' }, // Změněno na "cauldron"
    { id: 'indoorFireplace', label: 'indoorFireplace' }, // Změněno na "indoorFireplace"
    { id: 'outdoorFireplace', label: 'outdoorFireplace' }, // Změněno na "outdoorFireplace"
    { id: 'satellite', label: 'satellite' }, // Změněno na "satellite"
    { id: 'streamingService', label: 'streamingService' }, // Změněno na "streamingService"
];

export const sports = [
    { id: 'bicycleStorage', label: 'bicycleStorage' },
    { id: 'tenis', label: 'tenis' },
    { id: 'fotbal', label: 'fotbal' },
    { id: 'fitness', label: 'fitness' },
    { id: 'bowling', label: 'bowling' },
    { id: 'volleyball', label: 'volleyball' }, // Změněno na "volleyball"
    { id: 'billiard', label: 'billiard' },
    { id: 'tableTennis', label: 'tableTennis' }, // Změněno na "tableTennis"
    { id: 'bikeRental', label: 'bikeRental' }, // Změněno na "bikeRental"
];

export const others = [
    { id: 'crib', label: 'crib' },
    { id: 'highChair', label: 'highChair' },
    { id: 'playCorner', label: 'playCorner' },
    { id: 'darts', label: 'darts' },
    { id: 'suitableForEvents', label: 'suitableForEvents' }, // Změněno na "suitableForEvents"
    { id: 'playground', label: 'playground' }, // Změněno na "playground"
    { id: 'slide', label: 'slide' }, // Změněno na "slide"
    { id: 'trampoline', label: 'trampoline' },
    { id: 'swing', label: 'swing' },
    { id: 'climbingFrame', label: 'climbingFrame' }, // Změněno na "climbingFrame"
    { id: 'socialGames', label: 'socialGames' }, // Změněno na "socialGames"
];

export const eats = [
    { id: 'ranajky', label: 'breakfast' }, // Změněno na "breakfast"
    { id: 'obed', label: 'lunch' }, // Změněno na "lunch"
    { id: 'vecera', label: 'dinner' }, // Změněno na "dinner"
    { id: 'polpenzia', label: 'halfBoard' }, // Změněno na "halfBoard"
    { id: 'plna-penzia', label: 'fullBoard' }, // Změněno na "fullBoard"
];

import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import '../assets/css/index.css';
import authService from "../services/authService";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRotateBackward, faPlus} from "@fortawesome/free-solid-svg-icons";

// Simulovaná data pro grafy
const reservationData = {
    labels: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
    datasets: [
        {
            label: 'Počet rezervací',
            data: [5, 10, 3, 8, 15, 20, 35, 25, 12, 14, 8, 6],
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
        },
    ],
};

const trafficData = {
    labels: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
    datasets: [
        {
            label: 'Návštěvnost',
            data: [50, 75, 30, 85, 150, 200, 350, 300, 120, 140, 90, 70],
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
        },
    ],
};


const StatisticsPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!authService.isAuthenticated()) {
            navigate('/login');
        }
    });

    return (
        <div className="statistics-page box-padding">
            <div className="my-header">
                <h1>{t('Luxusní Chata na Vysokých Tatrách')}</h1>
                <div className="box-row">
                    <button className="add-chat-button" onClick={() => window.location.href = '/add-variant'}>
                        <FontAwesomeIcon icon={faPlus}/> {t('addVariant')}
                    </button>
                    <button className="add-chat-button" onClick={() => window.location.href = '/my'}>
                        <FontAwesomeIcon icon={faArrowRotateBackward}/> {t('back')}
                    </button>
                </div>
            </div>
            <div className="statistics-body">
                <div className="chart-container">
                    <h2>{t('Počet rezervací')}</h2>
                    <Bar data={reservationData} options={{responsive: true}}/>
                </div>
                <div className="chart-container">
                    <h2>{t('Návštěvnost')}</h2>
                    <Line data={trafficData} options={{responsive: true}}/>
                </div>
            </div>
        </div>
    );
};

export default StatisticsPage;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Home from "../components/Homepage/Home";
import Favourite from "../components/Homepage/Favourite";
import Properties from "../components/Homepage/Properties";
import Products from "../components/Homepage/Products";
import Add from "../components/Homepage/Add";
import '../assets/css/index.css';
function HomePage() {
    const { t } = useTranslation();

    return (
        <div>
            <Home />
            <Favourite />
            <Properties title={t('propertiesTitle')} />
            <Products title={t('productsTitle')} />
            <Add />
            <Products title={t('productsSecondTitle')} />
            <Properties title={t('propertiesSecondTitle')} />
        </div>
    );
}

export default HomePage;

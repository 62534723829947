import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

function Footer() {
    const { t } = useTranslation();

    return (
        <footer>
            <div className="footer-container">
                <div className="footer-column">
                    <h3>{t('Pre návštevníkov')}</h3>
                    <Link to="/simple/privacy-policy" className="footer-link">{t('Ochrana osobních údajů')}</Link>
                    <Link to="/simple/terms-of-use" className="footer-link">{t('Podmienky použivania')}</Link>
                </div>
                <div className="footer-column">
                    <h3>{t('Pre ubytovateľov')}</h3>
                    <Link to="/simple/register-accommodation" className="footer-link">{t('registrace ubytovanie')}</Link>
                    <Link to="/simple/portal-reviews" className="footer-link">{t('hodnoteni portoalu')}</Link>
                </div>
                <div className="footer-column">
                    <h3>{t('O nás')}</h3>
                    <Link to="/simple/contact" className="footer-link">{t('kontakt')}</Link>
                    <Link to="/simple/careers" className="footer-link">{t('Volne pracovni mista')}</Link>
                </div>
                <div className="footer-column border-left">
                    <p>{t('Sledujte nás:')}</p>
                    <div>
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label={t('Facebook')}>
                            <i className="fab fa-facebook-f social-icon"></i>
                        </a>
                        <span>|</span>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" aria-label={t('Instagram')}>
                            <i className="fab fa-instagram social-icon"></i>
                        </a>
                    </div>
                    <Link to="/simple/cookie-settings" className="footer-link">{t('Spravovať nastavenia cookies')}</Link>
                    <p>Copyright © 2024 Chatynapronajem.cz – Všetky práva vyhradené</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

import React, { useContext } from 'react';
import { SearchContext } from '../Context';
import heart from "../assets/icons/heart.png";
import tatry from "../assets/images/tatry.jpg";
import '../assets/css/search.css';

const SearchPage = () => {
    const { searchParams } = useContext(SearchContext);

    // Získání parametrů z contextu
    const locality = searchParams?.locality;
    const properties = searchParams?.properties || null;

    // Simulovaná data obrázků
    const images = [
        { id: 0, src: tatry, alt: 'Tatry' },
        { id: 1, src: tatry, alt: 'Nízké Tatry' },
        { id: 2, src: tatry, alt: 'Orava' },
        { id: 3, src: tatry, alt: 'Liptov' },
        { id: 4, src: tatry, alt: 'Slovenský Ráj' },
        { id: 5, src: tatry, alt: 'Vysoké Tatry' },
    ];

    const products = Array.from({ length: 20 }, (_, index) => ({
        ...images[index % images.length],
        id: index,
        locality: locality || `Locality ${index + 1}`,
    }));

    return (
        <div className="search-page box-padding-add">
            <div className="search-product-grid">
                {products.map((product) => (
                    <div className="search-grid-item" key={product.id}>
                        <a href="/detail" className="search-product-card">
                            <img src={product.src} alt={product.alt} className="search-product-image" />
                            <div className="search-labels-box">
                                <div className="search-label">Vyprodané</div>
                                <div className="search-label">Sleva</div>
                            </div>
                            <img src={heart} className="search-heart-icon" />
                            <div className="search-product-info">
                                <h3 className="search-product-title">{product.locality}</h3>
                                <p className="search-product-description">Popis chaty, její vybavení a další důležité informace.</p>
                                <p className="search-product-price">Cena za noc: 1500 Kč</p>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
            <div className="search-pagination">
                <button className="search-pagination-button">«</button>
                <button className="search-pagination-button">1</button>
                <button className="search-pagination-button">2</button>
                <button className="search-pagination-button">3</button>
                <button className="search-pagination-button">»</button>
            </div>
        </div>
    );
};

export default SearchPage;

import React, {useContext} from 'react';
import {Carousel} from 'react-responsive-carousel';
import {Link} from 'react-router-dom'; // Import Link pro navigaci
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import stylů carouselu
import tatry from '../../assets/images/tatry.jpg'; // Příklad obrázku
import ntatry from '../../assets/images/ntatry.jpg'; // Příklad obrázku
import orava from '../../assets/images/orava.png'; // Příklad obrázku
import vtatry from '../../assets/images/vtatry.jpg'; // Příklad obrázku
import liptov from '../../assets/images/liptov.jpg'; // Příklad obrázku
import slovraj from '../../assets/images/slovraj.jpg';
import {SearchContext} from "../../Context";
import {useTranslation} from "react-i18next"; // Příklad obrázku

function Properties(props) {

    const { t } = useTranslation();
    const {updateSearchParams} = useContext(SearchContext);

    const handleClick = (propreties) => {
        const locality = null;
        updateSearchParams({locality, propreties});
    };

    const images = [
        {id: 0, src: tatry, alt: 'Tatry'},
        {id: 1, src: ntatry, alt: 'Nízké Tatry'},
        {id: 2, src: orava, alt: 'Orava'},
        {id: 3, src: liptov, alt: 'Liptov'},
        {id: 4, src: slovraj, alt: 'Slovenský Ráj'},
        {id: 5, src: vtatry, alt: 'Vysoké Tatry'},
    ];

    const items = [
        {name: 'S bazénem', values: 651, photoId: 0},
        {name: 'Bez bazénu', values: 21, photoId: 1},
        {name: 'S wellness', values: 42, photoId: 2},
        {name: 'Rodinné', values: 69, photoId: 3},
        {name: 'Luxusní', values: 21, photoId: 4},
        {name: 'Na horách', values: 151, photoId: 5},
    ];

    return (
        <div className="box">
            <div className="home-box-left">
                <h2>{props.title}</h2>
                <Carousel className="properties-carousel" showThumbs={false} showStatus={false} infiniteLoop autoPlay>
                    <div className="types-grid box-padding">
                        {items.map((item, index) => (
                            <Link
                                to="/search"
                                className="grid-item relative item"
                                style={{gridColumn: 'span 1'}}
                                onClick={() => handleClick(item.name)}
                            >
                                <img src={images[item.photoId].src} alt={images[item.photoId].alt}
                                     className="types-img zoom-img"/>
                                <div className="carousel-text properties-text">
                                    <h3>{item.name}</h3>
                                    <span>{item.values} {t('options')}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                </Carousel>
            </div>
        </div>
    );
}

export default Properties;

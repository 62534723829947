import React, {useState, useEffect} from 'react';
import tatry from "../assets/images/tatry.jpg";
import heart from "../assets/icons/heart.png";
import {postRequest} from '../services/apiService';
import {Link} from "react-router-dom";
import config from "../Config";
const ArticleList = () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await postRequest(config.host + '/api/blog', {});
                // Předpokládám, že data jsou v response.data
                const articlesData = response.data.map((article, index) => ({
                    id: article.id,
                    title: article.title,
                    content: article.content,
                    src: tatry, // nebo article.image pokud existuje
                    alt: article.title,
                }));
                setArticles(articlesData);
            } catch (error) {
                console.error("Chyba při načítání článků:", error);
            }
        };

        fetchArticles();
    }, []);

    return (
        <div className="search-page box-padding-add">
            <div className="search-product-grid">
                {articles.map((article) => (
                    <div className="search-grid-item" key={article.id}>
                        <Link to={`/article/${article.slug}`} className="search-product-card">
                            <img src={article.src} alt={article.alt} className="search-product-image"/>
                            <div className="search-labels-box">
                                <div className="search-label">Vyprodané</div>
                                <div className="search-label">Sleva</div>
                            </div>
                            <img src={heart} className="search-heart-icon"/>
                            <div className="search-product-info">
                                <h3 className="search-product-title">{article.title}</h3>
                                <p className="search-product-description">{article.content}</p>
                                <p className="search-product-price">Cena za noc: 1500 Kč</p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            <div className="search-pagination">
                <button className="search-pagination-button">«</button>
                <button className="search-pagination-button">1</button>
                <button className="search-pagination-button">2</button>
                <button className="search-pagination-button">3</button>
                <button className="search-pagination-button">»</button>
            </div>
        </div>
    );
};

export default ArticleList;
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {getRequest, deleteRequest} from '../services/apiService'; // Import funkcí pro API požadavky
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ntatry from '.././assets/images/ntatry.jpg';
import vtatry from '.././assets/images/vtatry.jpg';
import liptov from '.././assets/images/liptov.jpg';
import {
    faPlus,
    faEye,
    faEdit,
    faTrash,
    faBackward,
    faFastBackward,
    faArrowRotateBackward
} from '@fortawesome/free-solid-svg-icons';
import config from '../Config'; //

const VariantsPage = () => {
    const {t} = useTranslation();
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const token = 'asdas'; // Nastavte skutečný token

    useEffect(() => {
        if (!token) {
            window.location.href = '/login';
        } else {
            fetchChats();
        }
    }, [token]);

    const fetchChats = async () => {
        setLoading(true);
        setError('');
        try {
            if (config.dev) {
                // Použijte simulovaná data
                setChats(getSimulatedChats());
            } else {
                // Získejte chaty přes API
                const response = await getRequest('/api/chats', token);
                if (response.length === 0) {
                    setChats(getSimulatedChats());
                } else {
                    setChats(response);
                }
            }
        } catch (err) {
            console.error('API Error:', err); // Ladicí zpráva
            setError(t('fetchChatsError'));
            setChats(getSimulatedChats());
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (chatId) => {
        if (window.confirm(t('confirmDelete'))) {
            try {
                if (!config.dev) {
                    await deleteRequest(`/api/chats/${chatId}`, token);
                }
                setChats(chats.filter(chat => chat.id !== chatId));
            } catch (err) {
                console.error('Delete Error:', err); // Ladicí zpráva
                setError(t('deleteChatError'));
            }
        }
    };

    // Simulovaná data
    const getSimulatedChats = () => [
        {id: 3, name: '1 Lůžko', image: liptov},
        {id: 1, name: '2 Lůžka', image: ntatry},
        {id: 2, name: '3 Lůžka', image: vtatry},
    ];

    return (
        <div className="box-padding">
            <div className="my-header">
                <h1 className="mar-0">{t('myProductsTitle')}</h1>
                <div className="box-row">
                    <button className="button" onClick={() => window.location.href = '/add-variant'}>
                        <FontAwesomeIcon icon={faPlus}/> {t('addVariant')}
                    </button>
                    <button className="button" onClick={() => window.location.href = '/my'}>
                        <FontAwesomeIcon icon={faArrowRotateBackward}/> {t('back')}
                    </button>
                </div>
            </div>
            {loading ? (
                <p>{t('loading')}</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                <ul className="chat-list">
                    {chats.map(product => (
                        <li key={product.id} className="product-item">
                            <div className="product-image-container" style={{backgroundImage: `url(${product.image})`}}>
                                <div className="product-name">
                                    <span>{product.name}</span>
                                </div>

                                <div className="show-info">
                                    <span>5 {t('todayShow')}</span>
                                </div>

                                <div className="actions">
                                    <button onClick={() => window.location.href = `/chat/${product.id}`}>
                                        <FontAwesomeIcon icon={faEye}/>
                                    </button>
                                    <button onClick={() => window.location.href = `/edit-chat/${product.id}`}>
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </button>
                                    <button onClick={() => handleDelete(product.id)}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default VariantsPage;

// src/pages/EditProfilPage.js
import React from 'react';
import {useTranslation} from 'react-i18next';
import useRegistration from '../hooks/useRegistration'; // Import custom hook
import RegistrationForm from '../components/Registration/RegistrationForm';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRotateBackward, faPlus} from "@fortawesome/free-solid-svg-icons";
import authService from "../services/authService";
import {useNavigate} from "react-router-dom"; // Import RegistrationForm

const EditProfilPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {
        formData,
        error,
        loading,
        handleChange,
        handleSubmit,
        handleBack,
    } = useRegistration();

    if (!authService.isAuthenticated()) {
        navigate('/login');
    }

    return (
        <div className="box-padding">
            <div className="my-header">
                <h1 className="mar-0">{t('myProductsTitle')}</h1>
                <div className="box-row">
                    <button className="add-chat-button" onClick={() => window.location.href = '/my'}>
                        <FontAwesomeIcon icon={faArrowRotateBackward}/> {t('back')}
                    </button>
                </div>
            </div>
            <div className="edit-profile-page">
                <div className="registration-form">
                    <h2>{t('editProfile')}</h2> {/* Adjust the title as needed */}
                    <RegistrationForm
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleBack={handleBack}
                        loading={loading}
                        error={error}
                    />
                </div>
            </div>
        </div>
    );
};

export default EditProfilPage;

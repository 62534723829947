// src/pages/RegisterPage.js
import React from 'react';
import {useTranslation} from 'react-i18next';
import useRegistration from '../hooks/useRegistration'; // Import custom hook
import RegistrationForm from '../components/Registration/RegistrationForm';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const RegisterPage = () => {
    const {t} = useTranslation();
    const {
        formData,
        selectedPackage,
        payment_period, // Opraveno na správný název
        showForm,
        error,
        loading,
        handlePackageChange,
        handlePaymentPeriodChange,
        handleSelect,
        handleBack,
        handleChange,
        handleSubmit,
    } = useRegistration();

    return (
        <div className="register-page">
            <div className="header-section">
                <h1 className="home-h1 max-w-100">{t('register_h1')}</h1>
                <span>{t('register_text')}</span>
                <a href="#form" className={'white'}>CHCI SE ZAREGISTROVAT</a>
            </div>
            <div className="home-box box-padding-add aling-start" id="form">
                <div className="register-box">

                    {/*<div className="text-block">*/}
                    {/*    <span><FontAwesomeIcon icon={faCheck} className="icon-2 green"/>{t('registerHere')}</span>*/}
                    {/*    <span><FontAwesomeIcon icon={faCheck} className="icon-2 green"/>{t('welcome')}</span>*/}
                    {/*    <span><FontAwesomeIcon icon={faCheck} className="icon-2 green"/>{t('notHaveAccount')}</span>*/}
                    {/*    <span><FontAwesomeIcon icon={faCheck} className="icon-2 green"/>{t('loading')}</span>*/}
                    {/*    <span><FontAwesomeIcon icon={faCheck} className="icon-2 green"/>{t('loginFail')}</span>*/}
                    {/*</div>*/}

                    {showForm && (
                        <div className="flex flex-column box-reg">
                            <div className="reg-form">
                                <RegistrationForm
                                    formData={formData}
                                    handleChange={handleChange}
                                    handleSubmit={handleSubmit}
                                    handleBack={handleBack}
                                    loading={loading}
                                    error={error}
                                />
                            </div>
                        </div>
                    )}

                    {!showForm && (
                        <div className="package-selection">
                            <div className="package-column package-first">
                                <h2 className="p-0">{t('choosePackage')}</h2>
                                <p className="h-19"></p>
                                <p>Kampane Google, Facebook, YouTube, Seznam a ďalšie</p>
                                <p>Neobmedzené množstvo fotografií a videí</p>
                                <p>Kalendár obsadenosti a prepojenie s Vaším webom</p>
                                <p>Recenzie Vášho ubytovania</p>
                                <p>Vlastná webová stránka</p>
                                <p>Preklad do CZ, PL, HU, EN, DE</p>
                                <p>Podrobné štatistiky návštevnosti</p>
                                <p>Umiestnenie na úvodnej stránke</p>
                                <p>Prednostné pozície vo vyhľadávaní</p>
                                <p>Prednostné pozície v lokalitách</p>
                                <p className="h-19"></p>
                                <div className="flex-row flex mar-1">
                                    <label htmlFor="payment-period">{t('paymentPeriod')}</label>
                                    <select
                                        id="payment-period"
                                        value={payment_period} // Opraveno na správný název
                                        onChange={handlePaymentPeriodChange}
                                    >
                                        <option value="6">6 {t('months')}</option>
                                        <option value="12">12 {t('months')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="package-column centered">
                                <h3 className="green">{t('standard')}</h3>
                                <div className="package-details">
                                    <p>od 37 € / mesiac</p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                </div>
                                <input
                                    type="radio"
                                    name="package"
                                    value="Standard"
                                    className="display-no"
                                    checked={selectedPackage === 'Standard'}
                                    onChange={() => handlePackageChange('Standard')}
                                />
                                <button className="button-60 search-btn m-auto"
                                        onClick={handleSelect}>{t('select')}</button>
                            </div>
                            <div className="package-column centered">
                                <h3 className="green">{t('premium')}</h3>
                                <div className="package-details">
                                    <p>od 37 € / mesiac</p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                    <p><FontAwesomeIcon icon={faCheck} className="green icon-2"/></p>
                                </div>
                                <input
                                    type="radio"
                                    name="package"
                                    value="Premium"
                                    className="display-no"
                                    checked={selectedPackage === 'Premium'}
                                    onChange={() => handlePackageChange('Premium')}
                                />
                                <button className="button-60 search-btn m-auto"
                                        onClick={handleSelect}>{t('select')}</button>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ntatry from '../assets/images/ntatry.jpg';
import vtatry from '../assets/images/vtatry.jpg';
import liptov from '../assets/images/liptov.jpg';
import {
    faPlus,
    faEye,
    faEdit,
    faTrash,
    faBed,
    faChartArea,
    faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import Modal from '../components/Modals/Modal'; // Import modálního okna
import config from '../Config'; //

const MyPage = () => {
    const { t } = useTranslation();
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false); // Stav pro zobrazení modálního okna
    const [selectedChatId, setSelectedChatId] = useState(null); // Stav pro ID chatů, které chceme smazat
    const token = 'asdas'; // Nastavte skutečný token

    useEffect(() => {
        if (!token) {
            window.location.href = '/login';
        } else {
            fetchChats();
        }
    }, [token]);

    const fetchChats = async () => {
        setLoading(true);
        setError('');
        try {
            // Načteme oblíbená ID z localStorage
            const favouriteIds = JSON.parse(localStorage.getItem('favourite')) || [];

            // Načteme všechny produkty z localStorage
            const storedProducts = JSON.parse(localStorage.getItem('products')) || getSimulatedChats();

            // Filtrujeme produkty na základě oblíbených ID
            const favouriteProducts = storedProducts.filter(product => favouriteIds.includes(product.id));
            setChats(favouriteProducts);
        } catch (err) {
            console.error('Data Error:', err); // Ladicí zpráva
            setError(t('fetchChatsError'));
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (selectedChatId !== null) {
            try {
                // Smazání z localStorage
                const updatedProducts = chats.filter(chat => chat.id !== selectedChatId);
                setChats(updatedProducts);
                localStorage.setItem('products', JSON.stringify(updatedProducts));
                // Smazání oblíbeného ID z localStorage
                const favourites = JSON.parse(localStorage.getItem('favourite')) || [];
                const updatedFavourites = favourites.filter(id => id !== selectedChatId);
                localStorage.setItem('favourite', JSON.stringify(updatedFavourites));
                setShowModal(false); // Skrytí modálního okna po smazání
            } catch (err) {
                console.error('Delete Error:', err); // Ladicí zpráva
                setError(t('deleteChatError'));
            }
        }
    };

    const handleDeleteClick = (chatId) => {
        setSelectedChatId(chatId);
        setShowModal(true);
    };

    // Simulovaná data pro produkty
    const getSimulatedChats = () => [
        { id: 1, name: 'Chat 1', image: ntatry },
        { id: 2, name: 'Chat 2', image: vtatry },
        { id: 3, name: 'Chat 3', image: liptov },
    ];

    // Funkce pro odhlášení
    const handleLogout = () => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        window.location.href = '/login';
    };

    return (
        <div className="box-padding">
            <div className="my-header">
                <h1 className="mar-0">{t('myFavourite')}</h1>
                <div className="box-row">
                    <button className="add-chat-button" onClick={() => window.location.href = '/statistics'}>
                        <FontAwesomeIcon icon={faChartArea} /> {t('showStatistics')}
                    </button>
                    <button className="add-chat-button" onClick={() => window.location.href = '/add-product'}>
                        <FontAwesomeIcon icon={faPlus} /> {t('addChat')}
                    </button>
                    <button className="add-chat-button logout-button" onClick={handleLogout}>
                        <FontAwesomeIcon icon={faSignOutAlt} /> {t('logout')}
                    </button>
                </div>
            </div>

            {loading ? (
                <p>{t('loading')}</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                <ul className="chat-list">
                    {chats.map(product => (
                        <li key={product.id} className="product-item">
                            <div className="product-image-container" style={{ backgroundImage: `url(${product.image})` }}>
                                <div className="product-name">
                                    <span>{product.name}</span>
                                </div>

                                <div className="show-info">
                                    <span>5 {t('todayShow')}</span>
                                </div>

                                <div className="actions">
                                    <button onClick={() => window.location.href = `/variants/`}>
                                        <FontAwesomeIcon icon={faBed} />
                                    </button>
                                    <button onClick={() => window.location.href = `/chat/${product.id}`}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                    <button onClick={() => window.location.href = `/edit-chat/${product.id}`}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button onClick={() => handleDeleteClick(product.id)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            )}

            {showModal && (
                <Modal
                    title={t('confirmDelete')}
                    onClose={() => setShowModal(false)}
                    onConfirm={handleDelete}
                    deleteTitle={t('delete')}
                />
            )}
        </div>
    );
};

export default MyPage;

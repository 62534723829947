// authService.js
class AuthService {
    login(token, rememberMe) {
        if (rememberMe) {
            localStorage.setItem('authToken', token);
        } else {
            sessionStorage.setItem('authToken', token);
        }
    }

    logout() {
        localStorage.removeItem('authToken');
        sessionStorage.removeItem('authToken');
    }

    isAuthenticated() {
        const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
        return !!token; // Vrací true, pokud je token v localStorage nebo sessionStorage
    }

    getToken() {
        return localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
    }
}

export default new AuthService();

import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faHamburger, faPlus, faSearch, faUser} from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo/logo.png';
import LangSwitch from './LangSwitch';
import heart from "../../assets/icons/heart.png";
import SearchModal from '../Modals/SearchModal'; // Import the new modal component
import '../../assets/css/navbar.css';
import Flag from "react-flagkit";
import menuItems from "./menuItems";
import authService from "../../services/authService";

function Navbar() {
    const token = authService.getToken();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const favourite = localStorage.favourite;

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    return (
        <div className="navbar">
            <div className="navbar-items">
                <div className="navbar-left">
                    <Link to="/" className="logo-link">
                        <img src={logo} alt="Logo" className="logo"/>
                    </Link>
                    <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
                        {menuItems.map((item, index) => (
                            <li key={index} className="menu-item">
                                <Link to={`/${item.name.toLowerCase().replace(/\s+/g, '-')}`}>
                                    {item.name}
                                    {item.subItems && <FontAwesomeIcon icon={faChevronDown} className="arrow-icon"/>}
                                </Link>
                                {item.subItems && (
                                    <ul className="submenu">
                                        <div className="box-shadow">
                                            {item.subItems.map((subItem, subIndex) => (
                                                <div key={subIndex} className="sub-items-div">
                                                    {subItem.name && (<span className="category">
                                                        {subItem.name === 'Chaty na Slovensku' && (
                                                            <Flag country="SK" style={{width: '24px', height: '16px'}}/>
                                                        )}
                                                            {subItem.name === 'Chaty v Českej republike' && (
                                                                <Flag country="CZ"
                                                                      style={{width: '24px', height: '16px'}}/>
                                                            )}
                                                            {subItem.name === 'Silvester 2024 na Slovensku' && (
                                                                <Flag country="SK"
                                                                      style={{width: '24px', height: '16px'}}/>
                                                            )}
                                                            {subItem.name === 'Silvester 2024 v Českej republike' && (
                                                                <Flag country="CZ"
                                                                      style={{width: '24px', height: '16px'}}/>
                                                            )}
                                                            {subItem.name}
                                                </span>
                                                    )}
                                                    <div className="grid-container">
                                                        {subItem.items.map((detail, detailIndex) => (
                                                            <div key={detailIndex} className="grid-item">
                                                                <Link
                                                                    to={`/${detail.name.toLowerCase().replace(/\s+/g, '-')}`}
                                                                    className="menu-a-item">
                                                                    <span>{detail.name}</span>
                                                                    {detail.number && <span
                                                                        className="item-number">({detail.number})</span>}
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="navbar-right">
                    <LangSwitch/>
                    <div className="icon-box" onClick={toggleModal}>
                        <FontAwesomeIcon icon={faSearch} className="icon"/>
                    </div>
                    {favourite && (
                        <div className="icon-box">
                            <Link to="/favourite">
                                <img src={heart} className="heart-icon-nav" alt="Favourites"/>
                            </Link>
                        </div>
                    )}
                    {!token && (
                        <div className="icon-box">
                            <Link to="/register">
                                <FontAwesomeIcon icon={faPlus} className="icon"/>
                            </Link>
                        </div>
                    )}
                    <div className="icon-box">
                        <Link to={token ? "/my" : "/login"}>
                            <FontAwesomeIcon icon={faUser} className="icon"/>
                        </Link>
                    </div>
                    <div className="hamburger" onClick={toggleMenu}>
                        <div className="icon-box">
                            <FontAwesomeIcon icon={faHamburger} className="icon"/>
                        </div>
                    </div>
                </div>
            </div>
            <SearchModal isVisible={isModalVisible} onClose={toggleModal}/>
        </div>
    );
}

export default Navbar;

import React, {useState, useEffect} from 'react';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import stylů
import tatry from '../../assets/images/tatry.jpg';
import ntatry from '../../assets/images/ntatry.jpg';
import orava from '../../assets/images/orava.png';
import vtatry from '../../assets/images/vtatry.jpg';
import liptov from '../../assets/images/liptov.jpg';
import slovraj from '../../assets/images/slovraj.jpg';
import heart from '../../assets/icons/heart.png';
import heart_used from '../../assets/icons/heart_used.png';
import {useTranslation} from 'react-i18next';

function Products(props) {
    const {t} = useTranslation();

    const images = [
        {id: 0, src: tatry, alt: 'Tatry'},
        {id: 1, src: ntatry, alt: 'Nízke Tatry'},
        {id: 2, src: orava, alt: 'Orava'},
        {id: 3, src: liptov, alt: 'Liptov'},
        {id: 4, src: slovraj, alt: 'Slovenský Raj'},
        {id: 5, src: vtatry, alt: 'Vysoké Tatry'},
        {id: 6, src: vtatry, alt: 'Vysoké Tatry'},
        {id: 7, src: vtatry, alt: 'Vysoké Tatry'},
        {id: 8, src: vtatry, alt: 'Vysoké Tatry'},
    ];

    // Stav pro sledování velikosti obrazovky
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [favourites, setFavourites] = useState(JSON.parse(localStorage.getItem('favourite')) || []);

    // Hook pro změnu velikosti okna
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Hook pro synchronizaci oblíbených položek s localStorage
    useEffect(() => {
        localStorage.setItem('favourite', JSON.stringify(favourites));
    }, [favourites]);

    // Rozdělení obrázků do skupin po 4 pro desktop
    const chunkArray = (arr, size) => {
        const result = [];
        for (let i = 0; i < arr.length; i += size) {
            result.push(arr.slice(i, i + size));
        }
        return result;
    };

    const handleFavourite = (id) => {
        const updatedFavourites = favourites.includes(id)
            ? favourites.filter(favId => favId !== id)
            : [...favourites, id];
        setFavourites(updatedFavourites);
    };

    const groupedImages = isMobile ? [images] : chunkArray(images, 4);

    return (
        <div className="box">
            <div className="home-box-left">
                <h2>{props.title}</h2>
                <Carousel
                    className="products-carousel"
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop
                    autoPlay
                    swipeable
                    showArrows={!isMobile}
                    centerMode={isMobile}
                    centerSlidePercentage={isMobile ? 100 : 25} // 100% šířky pro mobil, 25% pro desktop
                >
                    {groupedImages.map((group, index) => (
                        <div className="product-grid box-padding" key={index}>
                            {group.map((image, idx) => (
                                <Item
                                    id={image.id}
                                    key={idx}
                                    image={image}
                                    handleFavourite={handleFavourite}
                                    isFavourite={favourites.includes(image.id)}
                                    t={t}
                                />
                            ))}
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
}

const Item = ({id, image, handleFavourite, isFavourite, t}) => {
    return (
        <div className="search-grid-item" key={id}>
            <a href="/detail" className="search-product-card">
                <img src={image.src} alt={image.alt} className="search-product-image"/>
                <div className="search-labels-box">
                    <div className="search-label">Vyprodané</div>
                    <div className="search-label">Sleva</div>
                </div>
                <img src={isFavourite ? heart_used : heart} onClick={() => handleFavourite(id)} className="heart-icon"
                     alt="Favourite"/>
                <div className="search-product-info">
                    <h3 className="search-product-title">Ostrava</h3>
                    <p className="search-product-description">Popis chaty, její vybavení a další důležité informace.</p>
                    <p className="search-product-price">Cena za noc: 1500 Kč</p>
                </div>
            </a>
        </div>
    );
}

export default Products;

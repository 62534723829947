// src/LanguageSwitcher.js
import React from 'react';
import Select from 'react-select';
import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';

// Možnosti s vlajkami
const options = [
    { value: 'sk', label: <Flag country="SK" style={{ width: '24px', height: '16px' }} /> },
    { value: 'cz', label: <Flag country="CZ" style={{ width: '24px', height: '16px' }} /> },
    { value: 'hu', label: <Flag country="HU" style={{ width: '24px', height: '16px' }} /> }
];

// Vlastní komponenta pro SingleValue
const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        {data.label}
    </div>
);

// Vlastní komponenta pro Option
const customOption = (props) => (
    <div {...props.innerProps} style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        {props.data.label}
    </div>
);

const LangSwitch = () => {
    const { i18n } = useTranslation();

    // Funkce pro změnu jazyka
    const handleChange = (selectedOption) => {
        i18n.changeLanguage(selectedOption.value);
    };

    // Získání výchozí hodnoty na základě aktuálního jazyka nebo nastavení na 'sk' jako výchozí
    const defaultValue = options.find(option => option.value === i18n.language) || options[0];

    return (
        <Select
            options={options}
            onChange={handleChange}
            defaultValue={defaultValue}
            components={{ SingleValue: customSingleValue, Option: customOption }}
            isSearchable={false} // Zakáže vyhledávací pole
            isClearable={false}  // Zakáže možnost vymazání
            isRtl={false}       // Nastavení pro RTL (pokud není potřeba)
        />
    );
};

export default LangSwitch;
// src/components/SearchModal.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const SearchModal = ({ isVisible, onClose }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    const handleSearch = () => {
        // Here you should perform the search logic (e.g., API call)
        // For demonstration, we'll use dummy results
        setResults([`Result 1 for "${query}"`, `Result 2 for "${query}"`]);
    };

    return (
        isVisible ? (
            <div className="search-modal">
                <div className="modal-content">
                    <button className="close-button" onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                    />
                    <button onClick={handleSearch}>Search</button>
                    <div className="search-results">
                        {results.length ? results.map((result, index) => (
                            <div key={index} className="result-item">
                                {result}
                            </div>
                        )) : <p>No results found</p>}
                    </div>
                </div>
            </div>
        ) : null
    );
};

export default SearchModal;

const isLocalhost = window.location.hostname === 'localhost';
const dev = isLocalhost; // nastavení dev podle URL adresy

const host = "https://chatynapronajem.cz/backend/www";
const config = {
    dev,
    host
};

export default config;

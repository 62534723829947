import React, { createContext, useState, useEffect } from 'react';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [searchParams, setSearchParams] = useState(() => {
        // Load initial state from localStorage
        const savedParams = localStorage.getItem('searchParams');
        return savedParams ? JSON.parse(savedParams) : {};
    });

    useEffect(() => {
        // Save state to localStorage whenever it changes
        localStorage.setItem('searchParams', JSON.stringify(searchParams));
    }, [searchParams]);

    const updateSearchParams = (params) => {
        setSearchParams(params);
    };

    return (
        <SearchContext.Provider value={{ searchParams, updateSearchParams }}>
            {children}
        </SearchContext.Provider>
    );
};

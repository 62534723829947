// src/services/registrationService.js
import { postRequest } from './apiService'; // Import funkce pro POST požadavek

// Funkce pro registraci uživatele
export const registerUser = async (formData) => {
    try {
        const response = await postRequest('/auth/register', formData);
        return response; // Vrací odpověď ze serveru
    } catch (error) {
        throw new Error('Registration failed');
    }
};

// src/hooks/useRegistration.js
import {useState} from 'react';
import {registerUser} from '../services/registrationService';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const useRegistration = () => {
    const [formData, setFormData] = useState({
        email: '',
        salutation: 'pán',
        first_name: '',
        last_name: '',
        phone: '',
        companyName: '',
        street: '',
        city: '',
        postalCode: '',
        ico: '',
        dic: '',
        vat: ''
    });
    const [selectedPackage, setSelectedPackage] = useState('Standard');
    const [payment_period, setPaymentPeriod] = useState('12'); // Ujistěte se, že název je konzistentní
    const [showForm, setShowForm] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const handlePackageChange = (pkg) => setSelectedPackage(pkg);

    const handlePaymentPeriodChange = (e) => setPaymentPeriod(e.target.value);

    const handleSelect = () => setShowForm(true);

    const handleBack = () => setShowForm(false);

    const handleChange = (e) => {
        const {id, value} = e.target;
        setFormData((prevData) => ({...prevData, [id]: value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const data = {
            ...formData,
            package: selectedPackage,
            payment_period, // Ujistěte se, že název je konzistentní
        };

        try {
            await registerUser(data);
            navigate('/login');
        } catch (err) {
            setError(t('registration_fail'));
        } finally {
            setLoading(false);
        }
    };

    return {
        formData,
        selectedPackage,
        payment_period, // Ujistěte se, že název je konzistentní
        showForm,
        error,
        loading,
        handlePackageChange,
        handlePaymentPeriodChange,
        handleSelect,
        handleBack,
        handleChange,
        handleSubmit,
    };
};

export default useRegistration;

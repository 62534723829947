import React from 'react';

const Modal = ({ title, onClose, onConfirm, deleteTitle }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{title}</h2>
                <div className="modal-actions">
                    <button className="modal-button" onClick={onClose}>Zavřít</button>
                    <button className="modal-button confirm" onClick={onConfirm}>{deleteTitle}</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
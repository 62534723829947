import React, { useEffect, useState } from 'react';
import { postRequest } from '../services/apiService'; // Ensure this function is available for making POST requests
import { useTranslation } from 'react-i18next';
import config from "../Config";

const SimplePage = (props) => {
    const { t } = useTranslation();
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchContent = async () => {
            setLoading(true);
            setError('');
            try {
                const response = await postRequest(config.host + '/api/getPageContent', { page: props.page });

                if (response && response.content) {
                    setContent(response.content);
                } else {
                    setError(t('contentFetchFail'));
                }
            } catch (err) {
                console.error('Content fetch failed:', err);
                setError(t('contentFetchFail'));
            } finally {
                setLoading(false);
            }
        };

        fetchContent();
    }, [props.page, t]);

    return (
        <div className="simple-page">
            <h2>{t('simplePageTitle')}</h2>
            {loading && <p>{t('loading')}</p>}
            {error && <p className="error-message">{error}</p>}
            {!loading && !error && <div dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
    );
};

export default SimplePage;

import axios from 'axios';

// Nastavte základní URL pro vaše API
const API_BASE_URL = 'https://chatynapronajem.cz/backend/www'; // Ujistěte se, že tato URL je správná

// Vytvořte instanci axios s přednastavenými konfiguracemi
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        // Přidejte další globální hlavičky, pokud je třeba
    },
});

/**
 * General function to perform a POST request
 * @param {string} endpoint - The API endpoint URL
 * @param {object} data - Data to be sent in the request body
 * @param {object} [headers] - Optional request headers
 * @returns {Promise<object>} - Returns the API response
 */
export const postRequest = async (endpoint, data, headers = {}) => {
    try {
        const defaultHeaders = {
            'Content-Type': 'application/json',  // Default headers
        };

        // Merge default headers with custom headers
        const finalHeaders = { ...defaultHeaders, ...headers };

        const response = await api.post(endpoint, data, { headers: finalHeaders });
        return response.data;
    } catch (error) {
        console.error('API POST request error:', error);
        throw error;
    }
};

/**
 * Obecná funkce pro vykonání GET požadavku
 * @param {string} endpoint - Konec API endpointu
 * @param {object} [headers] - Volitelné hlavičky požadavku
 * @returns {Promise<object>} - Vrátí odpověď API
 */
export const getRequest = async (endpoint, headers = {}) => {
    try {
        const response = await api.get(endpoint, { headers });
        return response.data;
    } catch (error) {
        console.error('API GET request error:', error);
        throw error;
    }
};

/**
 * Obecná funkce pro vykonání DELETE požadavku
 * @param {string} url - URL API endpointu
 * @param {string} token - Token pro autentizaci
 * @returns {Promise<void>}
 */
export const deleteRequest = async (url, token) => {
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                // Přidejte další hlavičky, pokud je třeba
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    } catch (error) {
        console.error('API DELETE request error:', error);
        throw error;
    }
};

// Přidejte další metody podle potřeby (PUT, PATCH, atd.)

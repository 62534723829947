import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Carousel} from 'react-responsive-carousel';
import tatry from '../assets/images/tatry.jpg';
import ntatry from '../assets/images/ntatry.jpg';
import orava from '../assets/images/orava.png';
import vtatry from '../assets/images/vtatry.jpg';
import liptov from '../assets/images/liptov.jpg';
import slovraj from '../assets/images/slovraj.jpg';
// Simulovaná data produktu
const simulatedProduct = {
    name: 'Luxusní Chata na Vysokých Tatrách',
    location: 'Vysoké Tatry, Slovensko',

    images: [
        tatry,
        ntatry,
        orava,
        liptov,
        slovraj,
        vtatry,
    ],

    description: 'Tato luxusní chata nabízí úchvatný výhled na Vysoké Tatry a moderní vybavení pro vaši pohodlnou dovolenou.',
    properties: [
        'Bazén: 2',
        'Vířivka: 8',
        'Sauny: 5',
        'Kade: 2',
        'Lůžka: 101',
        'Přistýlky: 2',
        'Spální: 33',
        'Koupelny: 24',
        'Toalety: 26',
        'WiFi: Ano',
        'Sauna: Ano',
        'Vnitřní bazén: Ano',
        'Vířivka: Ano',
        'Koupací káď: Ano',
        'Krb: Ano',
        'Objekt je oplotený: Ano',
        'Povolené malé zvíře: Ano',
        'Pro rodiny s dětmi: Ano',
        'Parkovací místa: 30',
    ],
};

const ProductDetail = () => {
    const {t} = useTranslation();
    const [selectedDate, setSelectedDate] = useState('');
    const [numberOfGuests, setNumberOfGuests] = useState(1);

    // Slick Slider settings
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const handleGuestsChange = (e) => {
        setNumberOfGuests(e.target.value);
    };

    const handleBookNow = () => {
        // Funkce pro předběžnou rezervaci
        alert('Předběžná rezervace provedena!');
    };

    return (
        <div className="product-detail box-padding">
            <div className="product-body">
                <div className="product-left">
                    <div className="favourite-carousel-box">
                        <Carousel className="favourite-carousel" showThumbs={false} showStatus={false} infiniteLoop
                                  autoPlay>
                            {simulatedProduct.images.map((image, index) => (
                                <div key={index}>
                                    <img src={image} alt={`Slide ${index}`} className="detail-img"/>
                                </div>
                            ))}
                        </Carousel>

                        <div className="product-header">
                            <h1>{simulatedProduct.name}</h1>
                            <p>{simulatedProduct.location}</p>
                        </div>
                    </div>

                    <section className="product-description">
                        <h2>{t('description')}</h2>
                        <p>{simulatedProduct.description}</p>
                    </section>

                    <section className="product-properties">
                        <h2>{t('properties')}</h2>
                        <ul>
                            {simulatedProduct.properties.map((prop, index) => (
                                <li key={index}>{prop}</li>
                            ))}
                        </ul>
                    </section>
                </div>

                <div className="product-right">
                    <div className="reservation-box">
                        <h2>{t('reservation')}</h2>
                        <label>
                            {t('date')}
                            <input
                                type="date"
                                value={selectedDate}
                                onChange={handleDateChange}
                            />
                        </label>
                        <label>
                            {t('numberOfGuests')}
                            <input
                                type="number"
                                min="1"
                                value={numberOfGuests}
                                onChange={handleGuestsChange}
                            />
                        </label>
                        <button onClick={handleBookNow}>
                            {t('preBook')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;

import React from 'react';
import house from '../../assets/images/house.png';
import graph from '../../assets/images/graph.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Add() {
    const { t } = useTranslation();

    return (
        <div className="box box-padding-add">
            <div className="add-box relative">
                <h3 className="white">{t('addAccommodationTitle')}</h3>
                <span className="white">{t('addAccommodationDescription')}</span>
                <Link to="/register">
                    <button className="button-60 search-btn" role="button">{t('addButton')}</button>
                </Link>
                <img src={house} alt="house" className="house"/>
                <img src={graph} alt="graph" className="graph"/>
            </div>
        </div>
    );
}

export default Add;
